import React from "react"
import Helmet from 'react-helmet';
import Layout from "../components/layout"
import HeroHeader from "../components/heroHeader"

const IndexPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Experienced Web Designer — {site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <header>
          <h2 className="h2 article-title">About</h2>
        </header>
        <section className="about-text">
          <HeroHeader/>
        </section>
        <section className="service">
          <h3 className="h3 service-title">What i'm doing</h3>
          <ul className="service-list">
            <li className="service-item">
              <div className="service-icon-box">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M473.4 121.4c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"></path><path className="fa-secondary" d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6z"></path></svg>
              </div>
              <div className="service-content-box">
                <h4 className="h4 service-item-title">Web design</h4>
                <p className="service-item-text">
                  Crafting visually captivating digital experiences.
                </p>
              </div>
            </li>
            <li className="service-item">
              <div className="service-icon-box">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path className="fa-primary" d="M214.6 73.4c12.5 12.5 12.5 32.8 0 45.3L77.3 256 214.6 393.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3l160-160c12.5-12.5 32.8-12.5 45.3 0z"></path><path className="fa-secondary" d="M361.4 73.4c-12.5 12.5-12.5 32.8 0 45.3L498.7 256 361.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0z"></path></svg>
              </div>
              <div className="service-content-box">
                <h4 className="h4 service-item-title">Web development</h4>
                <p className="service-item-text">
                  Transforming ideas into functional websites.
                </p>
              </div>
            </li>
            <li className="service-item">
              <div className="service-icon-box">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M192 65.9C192 29.5 221.5 0 257.9 0c17.5 0 34.3 6.9 46.6 19.3L349.3 64H480c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-192 192c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V157.3l-44.7-44.7C198.9 100.2 192 83.4 192 65.9zM32 448H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32z"></path><path className="fa-secondary" d="M105.4 233.4L218.7 120 256 157.3v16l-92.6 92.6L209 448H143L97 263.8c-2.7-10.9 .5-22.4 8.4-30.4zM183.3 512c.5 0 .9 0 1.4 0h-1.4zM446.2 175.1c11 10.2 17.8 24.8 17.8 40.9c0 30.9-25.1 56-56 56c-16.2 0-30.7-6.8-40.9-17.8l79.1-79.1z"></path></svg>
              </div>
              <div className="service-content-box">
                <h4 className="h4 service-item-title">Reading books</h4>
                <p className="service-item-text">
                  Exploring new worlds one page at a time.
                </p>
              </div>
            </li>
            <li className="service-item">
              <div className="service-icon-box">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M192 144c13.3 0 24 10.7 24 24v32h32c13.3 0 24 10.7 24 24s-10.7 24-24 24H216v32c0 13.3-10.7 24-24 24s-24-10.7-24-24V248H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h32V168c0-13.3 10.7-24 24-24z"></path><path className="fa-secondary" d="M0 369.4v2.8C0 431.7 48.3 480 107.8 480c49.5 0 92.6-33.7 104.6-81.7L216 384H424l3.6 14.3c12 48 55.1 81.7 104.6 81.7C591.7 480 640 431.7 640 372.2v-2.8c0-6.3-.5-12.5-1.6-18.7L605.7 159.8c-8.6-50.2-40.9-93.2-90.3-105.5C469.5 42.9 403.8 32 320 32s-149.5 10.9-195.3 22.3C75.2 66.6 42.9 109.6 34.3 159.8L1.6 350.7C.5 356.9 0 363.1 0 369.4zM432 232a40 40 0 1 1 0 80 40 40 0 1 1 0-80zm24-56a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zm-240-8v32h32c13.3 0 24 10.7 24 24s-10.7 24-24 24H216v32c0 13.3-10.7 24-24 24s-24-10.7-24-24V248H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h32V168c0-13.3 10.7-24 24-24s24 10.7 24 24z"></path></svg>
              </div>
              <div className="service-content-box">
                <h4 className="h4 service-item-title">Gaming</h4>
                <p className="service-item-text">
                  Unwinding with immersive virtual adventures.
                </p>
              </div>
            </li>
          </ul>
        </section>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
